<template>
  <div>
    <a-upload :fileList="fileList" :multiple="true" listType="text" :customRequest="customRequest" @change="onChange">
      <a-button icon="upload">上传</a-button>
    </a-upload>
  </div>
</template>

<script>
import { authorizationCertificateUpload } from "@/apis/system";

export default {
  props: ["initialItems"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    customRequest({ file, onError, onSuccess }) {
      const dataForm = new FormData();
      dataForm.append("file", file);

      authorizationCertificateUpload(dataForm)
        .then((data) => onSuccess(data))
        .catch(() => onError({ name: "错误", message: "上传错误" }));
    },
    onChange({ fileList }) {
      this.fileList = fileList;
      const fileSet = [];
      for (const fileItem of fileList) {
        if (fileItem.status === "done") {
          fileSet.push(fileItem.response.id);
        }
      }

      this.$emit("change", fileSet);
    },
  },
  mounted() {
    const fileItems = [];
    if (this.initialItems) {
      for (const item of this.initialItems) {
        fileItems.push({
          uid: `-${Date.now() * Math.random()}`,
          name: item.name,
          status: "done",
          url: item.file,
          response: item,
        });
      }
    }

    this.fileList = fileItems;
  },
};
</script>

<style scoped></style>
